<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>Frequently Asked Questions</h1>
    </header>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'

export default {
  name: 'faqs',
  data() {
    return {}
  },
  components: {},
  methods: {
    ...mapActions({
    })
  },
  created() {
  }
}
</script>

<style lang="scss">